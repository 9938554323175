.applyJDPage {
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}

.applyJDContainer {
	display: flex;
	width: 90%;
	margin: 3rem 0;
	border-radius: 5px;
	box-shadow: 0px 0px 10.016666412353516px 2.504166603088379px #0000002e;
	background-color: #f3f3f3;
	justify-content: center;
	z-index: 1;
	position:relative;

	@media screen and (min-width: 730px) {
		width: 85%;
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.applyDescriptionContainer {
	display: flex;
	width: 95%;
	flex-direction: column;

	@media screen and (min-width: 730px) {
		width: 85%;
	}
}
.designIcon {
	height: 8rem;
	position: absolute;
	left: 0%;
	top: 0%;
	opacity: .5;
	z-index: 0;

	@media screen and (min-width: 730px) {
		height: 6rem;
		opacity: 1;
	}

	@media screen and (min-width: 1000px) {
		height: 8rem;
		
	}
	@media screen and (min-width: 1200px) {
	}

	@media screen and (min-width: 1400px) {

	}

	@media screen and (min-width: 1700px) {

	}
}
.descriptionInnerContainer {
	display: flex;
	flex-direction: column;
	z-index: 1;
}
.descriptionHeaderContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 1.875rem;
}
.descriptionLeft {
	display: flex;
	flex-direction: column;
	z-index: 1;
}
.jobRole {
	font-size: 1.125rem;
	font-weight: 600;
	text-align: left;

	@media screen and (min-width: 730px) {
		font-size: 1.625rem;
	}
	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.locationIcon {
	height: 0.635rem;
}
.jobLocation {
	font-size: 0.625rem;
	font-weight: 400;
	text-align: left;
	display: flex;
	align-items: center;
	gap: 0.3rem;

	@media screen and (min-width: 730px) {
		font-size: 0.8125rem;
	}
	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.jobStatus {
	font-size: 0.6875rem;
	font-weight: 400;
	text-align: left;
	display: flex;
	align-items: center;
	margin-top: 0.31rem;

	@media screen and (min-width: 730px) {
		font-size: 0.6875rem;
	}
	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}

	.statusIcon {
		height: 0.93rem;
		margin-right: 0.3125rem;
	}
	.jobscardStatuss {
		display: none;
	}
}

.descriptionRight {
	display: flex;
	align-items: center;
	font-size: 0.625rem;

	@media screen and (min-width: 730px) {
		font-size: 1rem;
	}
	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}

.fullTime {
	font-size: 0.625rem;
	background: #3db47b29;
	color: #3db47b;
	font-weight: 500;
	padding: 0.25rem 0.4375rem;
	border-radius: 0.5rem;

	@media screen and (min-width: 730px) {
		font-size: 0.8125rem;
	}
	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.internship {
	font-size: 0.625rem;
	background: rgba(7, 111, 237, 0.28);
	color: #013db5;
	font-weight: 500;
	border-radius: 0.5rem;
	padding: 0.25rem 0.625rem;
}

.contract {
	background: rgba(237, 176, 7, 0.28);
	color: #b57601;
	font-weight: 500;
	padding: 0.35rem 0.7rem;
	box-shadow: 0px 0px 2.537703275680542px 0.5075407028198242px #00000040;
	border-radius: 5px;
}

.descriptionBody {
	display: flex;
	flex-direction: column;
	height: 100%;
	background: white;
	justify-content: flex-start;
	align-items: flex-start;
	text-align: left;
	padding: 0.625rem;
	border-radius: 0.5rem;
	margin-top: 0.9375rem;

	h2 {
		font-size: 1.125rem;
		margin: 0;

		@media screen and (min-width: 730px) {
		}

		@media screen and (min-width: 1000px) {
		}

		@media screen and (min-width: 1400px) {
		}

		@media screen and (min-width: 1700px) {
		}
	}
	p {
		font-size: 0.875rem;
		margin: 0;

		@media screen and (min-width: 730px) {
		}

		@media screen and (min-width: 1000px) {
		}

		@media screen and (min-width: 1400px) {
		}

		@media screen and (min-width: 1700px) {
		}
	}

	ul {
		font-size: 0.875rem;
		padding-left: 1rem;

		@media screen and (min-width: 730px) {
		}

		@media screen and (min-width: 1000px) {
		}

		@media screen and (min-width: 1400px) {
		}

		@media screen and (min-width: 1700px) {
		}
	}

	@media screen and (min-width: 730px) {
		padding: 1.5rem;
	}
}

.buttonContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 1.875rem 0;

	@media screen and (min-width: 730px) {
		justify-content: flex-end;
	}
}
.goBackButton {
	border-radius: 0.5rem;
	padding: 0.375rem 0.625rem;
	outline: none;
	border: 1px solid #48a4aa;
	font-size: 0.875rem;
	font-weight: 400;
	color: #48a4aa;
	background-color: white;
	width: 6rem;
	cursor: pointer;

	@media screen and (min-width: 730px) {
		font-size: 0.875rem;
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.applyButton {
	border: none;
	outline: none;
	padding: 0.375rem 0.625rem;
	border-radius: 0.5rem;
	background: #48a4aa;
	font-size: 0.875rem;
	font-weight: 400;
	color: white;
	cursor: pointer;
	width: 6rem;
	margin-left: .625rem;

	@media screen and (min-width: 730px) {
		font-size: 0.875rem;
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}