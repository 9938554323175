* {
	transition: all 200ms ease-in-out;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
body {
	line-height: normal;
	max-width: 2000px;
}

button {
	cursor: pointer;
}
a {
	cursor: pointer;
	text-decoration: none;
}

:root {
	--nav_height: 3.5rem;
	@media screen and (min-width: 550px) {
		--nav_height: 5rem;
	}
	@media screen and (min-width: 730px) {
		--nav_height: 4rem;
	}
	@media screen and (min-width: 1000px) {
		--nav_height: 4rem;
	}

	@media screen and (min-width: 1400px) {
		--nav_height: 5rem;
	}

	@media screen and (min-width: 1700px) {
		--nav_height: 5rem;
	}
}
