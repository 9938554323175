@import '../../App.scss';

.careersContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	// padding: calc(var(--nav_height)) 0 0 0;

	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}

.careerSection1 {
	height: 100vh;
	display: flex;
	position:relative;
	align-items: flex-start;
	justify-content: center;

	@media screen and (min-width: 550px) {
		height: 70vh;

	}
	@media screen and (min-width: 1000px) {
		height: 100vh;
	}
	@media screen and (min-width: 1400px) {
	}
	@media screen and (min-width: 1700px) {
	}
}

.careerSection1::before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	z-index: 0;
	display: block;
	background-image: url(../../assets/webp/careers-mobile.webp);
	background-repeat: no-repeat;
	background-size: cover;
	background-repeat: no-repeat;
	// width: 100%;
	height: 100%;
	// top: 0;

	@media screen and (min-width: 550px) {
		display: block;
		background-image: url(../../assets/webp/careers-desktop.webp);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 100%;
		width:100%;
		opacity: .8;
	}
}

.careerSection1Child {
	z-index: 1;
	margin-top: 10%;
	height: 95%;
	width:88%;
	@media screen and (min-width: 730px) {
	}
}

.careerSection1ChildLeft{
	width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

	@media screen and (min-width: 730px) {
		width: 70%;
		margin-top: -5rem;
	}

}

.sectionheading {
	line-height: 1.3;
	color: #1c1b1f !important;

	.section1Header {
		font-size: 1.69rem;
		font-weight: 600 !important;
		text-align: left;
		margin-top: 1.25rem;

		@media screen and (min-width: 730px) {
			font-size: 2.2rem;
			font-weight: 600 !important;
		}
		@media screen and (min-width: 1000px) {
		}

		@media screen and (min-width: 1400px) {
		}

		@media screen and (min-width: 1700px) {
		}
	}
}

.sectionBody {
	font-size: 0.75rem;
	text-align: left;
	color: #444343;
	padding: 2% 0;
	max-width: 30rem;

	@media screen and (min-width: 730px) {
		font-size: 0.875rem;
	}
	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}

.exploreButton {
	width: 9.9rem;
	padding: 0.625rem .93rem;
	color: white;
	background-color: #0548d3;
	border: none;
	border-radius: 3px;
	font-size: 0.875rem;
	font-weight: 500;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;

	@media screen and (min-width: 730px) {
		width: 9.3rem;
	}
}

.careerSection2 {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent !important;
	flex-direction: column;

	@media screen and (min-width: 730px) {
	}
}

.sectionBody2 {
	margin: 2.5rem 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 85%;
	flex-direction: column;

	img {
		width: 90%;
		@media screen and (min-width: 730px) {
			width: 65%;
		}
	}
}

.sectionHeading2 {
	display: flex;
	font-size: 1.5rem;
	font-weight: 500;

	@media screen and (min-width: 730px) {
		font-size: 1.875rem;
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.sectionContents {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-template-rows: auto;
	gap: 1.5rem;
}

.Section2Container {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 1rem;

	@media screen and (min-width: 730px) {
		flex-direction: row;
	}
}

.gridcontents {
	font-size: 1.25rem;
	color: #383838;
	display: flex;
	flex-direction: column;
	margin-top: 1.25rem;

	@media screen and (min-width: 730px) {
		width: 20%;
		font-size: 1.25rem;
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.gridText {
	font-size: 0.875rem;
	text-align: left;
	margin-top: 0.3125rem;

	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}

.dotHeader {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}
.dot {
	width: .93rem;
	height: .93rem;
	border-radius: 50%;
	background-color: #48a4aa;
	display: inline-block;
}
.pointHeader {
	font-weight: 500;
	text-align: left;
}

.careerSection3 {
	display: flex;
	flex-direction: column;
	width: 100%;
	background: #dadada;
	text-align: center;
	position: relative;
	align-items: center;
}
.sectionBody3 {
	display: flex;
	flex-direction: column;
	padding: 1.25rem 0;
}
.sectionHeading3 {
	font-size: 1.875rem;
	font-weight: 600;
	z-index: 1;
	span {
		font-weight: 600;
		color: #dc2452;
	}
	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.sectionSubHeader {
	font-size: 0.875rem;
	color: #333333;
	z-index: 1;
	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.sectionArrows {
	position: absolute;
	left: 2%;
	height: auto;
	width: 8%;
	top: 5%;
	z-index: 0;
	@media screen and (min-width: 730px) {
		left: 5%;
	}
}
.sectiondots {
	position: absolute;
	right: 5%;
	width: 15%;
	height: auto;
	top: 0;
	transform: scale(1.5);
	@media screen and (min-width: 730px) {

	}
}
.sectionImgContainer {
	display: flex;
	flex-direction: column;
	gap: 4.3rem;
	align-items: center;
	justify-content: center;

	.zirohMerch {
		display: none;

		@media screen and (min-width: 730px) {
			display: flex;
		}
	}
	.zirohMerchMobile {
		display: flex;

		@media screen and (min-width: 730px) {
			display: none;
		}
	}
	.lifeAtZiroh {
		display: none;

		@media screen and (min-width: 730px) {
			display: flex;
		}
	}
	.lifeAtZirohMobile {
		display: flex;
		margin-bottom: 1.25rem;

		@media screen and (min-width: 730px) {
			display: none;
		}
	}
	.SectionImg1 {
		display: flex;
		justify-content: center;
	}
	img {
		width: 100%;
		@media screen and (min-width: 730px) {
			width: 100%;
		}
	}
}
.sectionTestimonials {
	display: flex;
	justify-content: center;
	background-color: rgba(214, 234, 255, 0.42);
	position: relative;
	padding: 1rem;
	margin-bottom: 0.93rem;
	.sectionArrows {
		position: absolute;
		width: 7%;
		top: 10%;
		left: 15%;
		transform: rotate(-90deg);
		z-index: 0;
	}
	.sectionArrows2 {
		position: absolute;
		width: 7%;
		bottom: 5%;
		right: 5%;
		z-index: 0;
	}
	.sectiondots {
		position: absolute;
		width: 25%;
		left: -11%;
		top: 60%;
		opacity: 0.5;
		z-index: 0;
		@media screen and (min-width: 730px) {
			width: 15%;
		}
	}

	.sectiondots2 {
		position: absolute;
		width: 10%;
		right: 0%;
		top: 25%;
		z-index: 0;
		opacity: 0.5;
	}
}

.testimonialsBody {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
	justify-content: center;
}

.testimonialsHeader {
	display: flex;
	flex-direction: column;
	font-size: 1rem;
	align-items: center;
	text-align: center;
	font-weight: 600;
	margin: 1.7rem 0;
	z-index: 1;

	@media screen and (min-width: 730px) {
		font-size: 1.875rem;
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}


.testimonialBox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 85%;
	height: 16rem;
	background: white;
	padding: 2rem;
	border-radius: 0.65rem;

	@media screen and (min-width: 730px) {
		width: 20rem;
	}
}

.userInfoName {
	margin-top: 1rem;
	font-weight: 600;
	font-size: 0.9375rem;
}

.userInfoTestimonial {
	font-size: 0.78rem;
}

.userInfoDesignation {
	font-size: 0.625rem;
}

.testimonialArrow {
	height: 1rem;

	@media screen and (min-width: 730px) {
		height: 2rem;
	}
}

.testimonialCarousel {
	width: 100%;

	@media screen and (min-width: 730px) {
		width: 50%;
	}
}

.carouselWrapper {
	padding: 1rem 0 2rem 0;
	width: 100%;
}

.carouselContents {
	display: flex;
	width: 100%;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	opacity: 0.5;
	background: none;
	height: 100%;
}
.carouselActive {
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 1;
	cursor: auto;
	transform: scale(1.6);
	transition: transform 0.4s ease;
	background: none;
	img {
		width: 2rem;
		border-radius: 50%;
		background: radial-gradient(
			51.45% 51.45% at 50% 50%,
			#285b5f 0%,
			#3a8388 89.06%,
			#62dde6 100%
		);
		padding: 3px;
	}
}

.profileImg {
	height: 2rem;
}

.jobOpeningsContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url(../../assets/webp/job_opening_mobile_bg.webp);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
	overflow-x: hidden;
	width: 100%;

	@media screen and (min-width: 730px) {
		background-image: url(../../assets/webp/job_opening_bg.webp);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: bottom right;
		overflow-x: hidden;
	}
}

.jobOpeningHeader {
	align-items: center;
	width: 85%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 12rem 0;
	position: relative;

	@media screen and (min-width: 730px) {
		padding: 4rem 0;
	}
}

.jobOpeningText {
	color: white;
	font-weight: 600;
	font-size: 1.5rem;
	text-align: left;
	letter-spacing: 2px;
	position: absolute;
	top: 20%;
	left: -5%;
	display: flex;

	@media screen and (min-width: 730px) {
		font-size: 2rem;
		text-align: center;
		position: relative;
		display: none;
	}
	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}

.jobOpeningTextDesktop {
	color: white;
	font-weight: 600;
	font-size: 1.5rem;
	text-align: center;
	position: absolute;
	top: 20%;
	left: 0%;
	display: none;

	@media screen and (min-width: 730px) {
		font-size: 2rem;
		text-align: center;
		position: relative;
		display: flex;
		top: 20%;
		left: 0%;
	}
	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}

.jobsCardSectionContainer {
	display: flex;
	width: 100%;
	justify-content: center;
	padding: 3rem 0;

	@media screen and (min-width: 730px) {
		padding: 0;
	}
}

.jobCardsContainer {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-template-rows: auto;
	gap: 2rem;
	width: 80%;

	@media screen and (min-width: 730px) {
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: auto;
		gap: 4rem;
		padding: 3.125rem 0;
		width: 85%;
	}
}

.jobsCard {
	display: flex;
	flex-direction: column;
	background-color: rgb(243, 243, 243);
	box-shadow: rgba(0, 0, 0, 0.18) 0px 0px 5px 1px;
	position: relative;
	border-radius: 0.5rem;
	
}
.jobsCardHeaderContainer{
	display: flex;
	justify-content: space-between;
}
.jobCardsHeader {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.DesignIcon {
	height: 3rem;
}

.jobsCardSubheader {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}

.jobscardStatusNone {
	display: none;
}

.statusIcon {
	height: 0.93rem;
	margin-right: 0.43rem;

	@media screen and (min-width: 730px) {
		height: 0.93rem;
	}
}

.jobscardStatus {
	display: flex;
	align-items: center;
	margin-right: 0.9375rem;
	color: #383838;
	font-size: 0.6875rem;
}

.jobsCardContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
}

.JobsCardInnerContainer {
	display: flex;
	flex-direction: column;
	width: 85%;
	align-items: flex-start;
}

.Jobsection {
	display: flex;
	flex-direction: column;
}

.jobsRole {
	color: #383838;
	font-weight: 600;
	font-size: 1.125rem;
	text-align: left;
}

.jobsDetails {
	min-width: 40%;
	display: flex;
	font-size: 0.625rem;
	margin: 1rem 0;
}

.jobsDetails p {
	padding: 0.35rem 0.7rem;
	box-shadow: 0px 0px 2.537703275680542px 0.5075407028198242px #00000040;
	border-radius: 5px;
	margin-right: 0.7rem;
}

.fullTime {
	background: #3db47b29;
	color: #3db47b;
	font-weight: 500;
	padding: 0.35rem 0.7rem;
	box-shadow: 0px 0px 2.537703275680542px 0.5075407028198242px #00000040;
	border-radius: 5px;
}

.internship {
	background: rgba(7, 111, 237, 0.28);
	color: #013db5;
	font-weight: 500;
	padding: 0.35rem 0.7rem;
	box-shadow: 0px 0px 2.537703275680542px 0.5075407028198242px #00000040;
	border-radius: 5px;
}

.contract {
	background: rgba(237, 176, 7, 0.28);
	color: #b57601;
	font-weight: 500;
	padding: 0.35rem 0.7rem;
	box-shadow: 0px 0px 2.537703275680542px 0.5075407028198242px #00000040;
	border-radius: 5px;
}

.jobsLocation {
	color: #383838;
	font-size: 0.75rem;
	display: flex;
	align-items: center;
	gap: 0.3rem;
}

.locationIcon {
	height: 0.7rem;
	@media screen and (min-width: 730px) {
		height: 0.7rem;
	}
}

.jobsDescription {
	margin: 0.8125rem 0;
	font-size: 0.8125rem;
	color: #686868;
	text-align: left;
	margin-bottom: 5rem;
}

.applyButton {
	width: 100%;
	display: flex;
	justify-content: space-between;
	justify-content: flex-end;
	position: absolute;
	bottom: 0;
	right: 1.25rem;
}

.applyButtonLink {
	display: flex;
	width: 100%;
	min-width: 40%;
	justify-content: flex-end;
	text-decoration: none;
}

.applyButton button {
	border: none;
	outline: none;
	padding: 0.4rem 0.4375rem;
	border-radius: 0.3125rem;
	margin-bottom: 1.5rem;
	min-width: 40%;
	background: #48a4aa;
	font-size: 0.875rem;
	font-weight: 400;
	color: white;

	@media screen and (min-width: 730px) {
		padding: 0.375rem 0.4375rem;
	}
}