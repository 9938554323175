.navbarContainer {
	height: var(--nav_height);
    background: white;
	// background: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(4px);
	color: var(--text_color1);
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	top: 0;
	z-index: 10;
	box-shadow: 0px 8px 8px 2px rgba(109, 141, 173, 0.15);

	.navbarMenu {
		display: flex;
		padding: 0.6875rem 1.4375rem;
		align-items: center;
		width: 100vw;
		max-width: 2500px;
	}
	.logo {
		height: 1.2rem;

		@media screen and (min-width: 550px) {
			height: 1.3rem;
		}

		@media screen and (min-width: 730px) {
			height: 1.5rem;
		}

		@media screen and (min-width: 1400px) {
			height: 1.5rem;
		}

		@media screen and (min-width: 1700px) {
			height: 1.9rem;
		}
	}
}
