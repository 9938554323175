.formPage {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 80vh;
	position: relative;

	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.formContainer {
	display: flex;
	width: 90%;
	margin: 6rem 0;
	border-radius: 5px;
	box-shadow: 0px 0px 10.016666412353516px 2.504166603088379px #0000002e;
	background-color: #f3f3f3;
	justify-content: center;
	position: relative;

	@media screen and (min-width: 730px) {
		width: 85%;
	}
}
.designIcon {
	height: 6rem;
	position: absolute;
	left: 0%;
	top: 0%;
	opacity: 0.5;

	@media screen and (min-width: 730px) {
		height: 6rem;
		opacity: 1;
	}
	@media screen and (min-width: 1000px) {
		height: 8rem;
	}

	@media screen and (min-width: 1400px) {
		height: 8.5rem;
	}

	@media screen and (min-width: 1700px) {
	}
}
.formInnerContainer {
	display: flex;
	width: 90%;
	flex-direction: column;
}
.descriptionHeaderContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 1.8rem;
	padding-bottom: 1rem;
}
.descriptionLeft {
	display: flex;
	flex-direction: column;
}
.jobRole {
	font-size: 1.125rem;
	font-weight: 600;
	text-align: left;
	z-index: 1;

	@media screen and (min-width: 730px) {
		font-size: 1.625rem;
	}
	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.jobLocationStatus {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 0.31rem;
	gap: 0.5rem;
	z-index: 1;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		align-items: center;
		margin-top: 0.31rem;
	}
}
.jobLocation {
	display: flex;
	align-items: center;
	gap: 0.3rem;
	font-size: 0.6875rem;
	font-weight: 400;

	.locationIcon {
		height: 0.93rem;
	}
}
.jobStatus {
	display: flex;
	align-items: center;
	gap: 0.3rem;
	font-size: 0.6875rem;
	font-weight: 400;

	.statusIcon {
		height: 1rem;
	}
	.jobscardStatuss {
		display: none;
	}
}

.descriptionRight {
	display: flex;
	align-items: center;
	font-size: 0.8125rem;

	@media screen and (min-width: 730px) {
	}
}

.fullTime {
	background: #3db47b29;
	color: #3db47b;
	font-weight: 500;
	padding: 0.25rem 0.625rem;
	border-radius: 0.5rem;
}
.internship {
	background: rgba(7, 111, 237, 0.28);
	color: #013db5;
	font-weight: 500;
	padding: 0.25rem 0.625rem;
	border-radius: 0.5rem;
}

.contract {
	background: rgba(237, 176, 7, 0.28);
	color: #b57601;
	font-weight: 500;
	padding: 0.35rem 0.7rem;
	box-shadow: 0px 0px 2.537703275680542px 0.5075407028198242px #00000040;
	border-radius: 5px;
}

hr {
	margin: 0;
}
.jobApplicationForm {
	display: flex;
	padding-top: 1rem;
	flex-direction: column;
}
.form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.formInputContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	gap: 0.5rem;

	@media screen and (min-width: 730px) {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		gap: 0;
	}
}
.FormInputs {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	border-radius: 0.5rem;

	@media screen and (min-width: 730px) {
		width: 48%;
	}
}
.inputWrapper {
	padding: 0.4375rem;
	border-radius: 0.5rem;
	width: 54%;
	background: white;

	input {
		width: 100%;
		font-size: 0.7rem;
		color: #383838;
		outline: none;
		border: none;
		// border-radius: 0.5rem;
		padding: 0;
		margin: 0;

		@media screen and (min-width: 730px) {
			font-size: 0.8rem;
		}
		@media screen and (min-width: 1000px) {
		}

		@media screen and (min-width: 1400px) {
		}

		@media screen and (min-width: 1700px) {
		}
	}

	@media screen and (min-width: 730px) {
		width: 70%;
	}
}
.selectWrapper {
	background-color: white;
	border-radius: 0.5rem;
	padding: 0.4375rem;
	width: 54%;

	@media screen and (min-width: 730px) {
		width: 70%;
	}
}
select {
	width: 95%;
	border: none;
	outline: none;
	color: #383838;
	border-radius: 0.5rem;
	font-size: 0.6875rem;
	background: none;
	// font-size: .4rem;

	@media screen and (min-width: 730px) {
		font-size: 0.68rem;
	}
	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
select:invalid {
	color: #383838;
}

label {
	font-size: 0.9375rem;
}
.radioButtonWrapper {
	display: flex;
	flex-direction: row;
	width: 54%;
	background: white;
	border-radius: 0.5rem;
	padding: 0.4375rem;
	gap: 0.5rem;

	label {
		font-size: 0.5375rem;

		@media screen and (min-width: 730px) {
			font-size: 0.6875rem;
		}
	}
	@media screen and (min-width: 730px) {
		width: 70%;
	}
}
input[type='radio'] {
	width: auto;
}

input[type='radio']::after {
	width: 0.93rem;
	height: 0.93rem;
	border-radius: 0.93rem;
	top: -2px;
	left: -1px;
	position: relative;
	background-color: darkgray;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 2px solid white;
	padding: 0;
	margin: 0;
}

input[type='radio']:checked:after {
	width: 0.93rem;
	height: 0.93rem;
	border-radius: 0.93rem;
	top: -2px;
	left: -1px;
	position: relative;
	background-color: #48a4aa;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 2px solid white;
}
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.radioButton {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.3rem;

	@media screen and (min-width: 730px) {
		gap: 0.5rem;
	}
}
.labelAndDays {
	display: flex;
	gap: 0.3rem;

	@media screen and (min-width: 730px) {
		gap: 0.5rem;
	}
}
.noticeDuration {
	font-weight: 500;
	color: black;
	font-size: 0.5375rem;

	@media screen and (min-width: 730px) {
		font-size: 0.6875rem;
	}
}
.noticeValue {
	width: auto;
	position: absolute;
	visibility: visible;
	top: 54%;
	right: 35%;
	background-color: white;
	padding: 0.4375rem;
	z-index: 2;
	box-shadow: 0px 0px 10.016666412353516px 2.504166603088379px #0000002e;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 0.6875rem;
	// gap: .3rem;

	@media screen and (min-width: 730px) {
		top: 50%;
		right: 32%;
	}
}
.NotServing {
	right: 20%;
	@media screen and (min-width: 730px) {
		right: 25%;
	}
}
.noticeValue p {
	padding: 0.4rem;
	font-size: 0.6875rem;
	width: 100%;
	border-radius: 8px;
}
.noticeValue p:hover {
	background-color: #e0e0e0;
	cursor: pointer;
}

.CtcWrapper {
	display: flex;
	background: white;
	padding: 0.4375rem;
	align-items: center;
	border-radius: 0.5rem;
	width: 54%;
	justify-content: space-between;
	font-size: 0.6875rem;

	input {
		width: 60%;
		padding: 0;
		border: none;
		outline: none;

		@media screen and (min-width: 730px) {
			width: 80%;
			font-size: 0.6875rem;
		}
	}

	@media screen and (min-width: 730px) {
		width: 70%;
	}
}
.ctcText {
	font-size: 0.6875rem;

	@media screen and (min-width: 730px) {
		font-size: 0.6875rem;
	}
}
.inputFileForm {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: flex-start;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
}
.fileInput {
	background: white;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0.4375rem;
	border-radius: 0.5rem;
	align-items: center;
	position: relative;
	margin-top: 0.3125rem;

	input[type='file'] {
		opacity: 0;
		right: 0;
		position: absolute;
		width: 100%;
		z-index: 1;
		cursor: pointer;

		@media screen and (min-width: 730px) {
			width: 100%;
		}
	}
	@media screen and (min-width: 730px) {
		width: 85.5%;
		margin-top: 0;
	}
}
.selectFile {
	font-size: 0.6875rem;

	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.browseButton {
	background-color: #48a4aa;
	border: none;
	outline: none;
	color: white !important ;
	font-weight: 600;
	border-radius: .5rem;
	padding: 0.375rem 0.625rem;
	cursor: pointer;
	position: absolute;
	right: 0;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.6875rem;
	width: 5rem;

	@media screen and (min-width: 730px) {
		font-size: 1rem;
		padding: 0.3rem 1.2rem;
		width: auto;
	}
	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}
.browseInput {
	cursor: pointer;
}
.buttonContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin: 1.875rem 0;

	@media screen and (min-width: 730px) {
		margin-top: 3rem;
	}
}
.goBackButton {
	border-radius: 0.3125rem;
	padding: 0.375rem 0.625rem;
	outline: none;
	border: 1px solid #48a4aa;
	font-size: 0.875rem;
	font-weight: 400;
	color: #48a4aa;
	background-color: white;
	width: 6rem;
	margin-right: 0.625rem;
	cursor: pointer;
}
.applyButton {
	border: none;
	outline: none;
	padding: 0.375rem 0.625rem;
	border-radius: 0.3125rem;
	// margin-bottom: 2.5rem;
	background: #48a4aa;
	font-size: 0.875rem;
	font-weight: 400;
	color: white;
	width: 6rem;
	cursor: pointer;
}

.applyButton:disabled {
	cursor: none;
}

.submitFormModal {
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(124, 123, 124, 0.43);
	z-index: 2;
}
.SubmitForm {
	width: 70%;
	border-radius: 0.5rem;
	background-color: #fefefe;
	padding: 1.875rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 0px 9px 2px #00000040;
	text-align: center;
	overflow: auto;
	position: relative;

	@media screen and (min-width: 730px) {
		width: 50%;
	}
}
.SubmittedGif {
	width: 9.9rem;

	@media screen and (min-width: 730px) {
		width: 12.3rem;
	}
}
.submittingStatus {
	font-size: 1.25rem;
	font-weight: 500;
}
.submittingMsg {
	font-size: 0.875rem;
	font-weight: 500;
}